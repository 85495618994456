import { isBreakable } from "./insert-separators.js";
import { CharClass, LanguageClass } from "./util-text-classes.js";
import { applyWrapperStyle, applyLatinStyle, applyNoBreaksStyle, createKerning } from "./util-tags.js";
import * as util from "./util-regex.js";
const applyStyleToText = (currentNodeValue, nextNodeValue, options) => {
  if (currentNodeValue === " ") {
    return currentNodeValue;
  }
  return applyWrapperStyle(currentNodeValue, options.useWordBreak);
};
const applyStyleToSegment = (currentSegment, nextSegment, options) => {
  if (currentSegment === " ") {
    return currentSegment;
  }
  if (options.wrapLatin && LanguageClass.isLatin(currentSegment)) {
    return currentSegment.replace(util.latinRegex, (match) => applyLatinStyle(match));
  }
  if (options.noSpaceBetweenNoBreaks && CharClass.shouldNotBreak(currentSegment)) {
    return currentSegment.replace(util.noBreakRulesRegex, (match) => applyNoBreaksStyle(match));
  }
  return currentSegment;
};
const applyKerningToSegment = (currentSegment, nextSegment, options) => {
  const chars = [...currentSegment];
  const kernedChars = chars.map((currentChar, i) => {
    const nextChar = chars[i + 1] || nextSegment[0] || "";
    const kerningRule = options.kerningRules?.find(
      (rule) => rule.between[0] === currentChar && rule.between[1] === nextChar
    );
    if (kerningRule) {
      const kerningValue = typeof kerningRule.value === "number" ? kerningRule.value : parseInt(kerningRule.value, 10);
      const breakable = options.useWordBreak ? false : isBreakable(currentChar, nextChar);
      return currentChar + createKerning(kerningValue, breakable);
    }
    return currentChar;
  });
  return kernedChars.join("");
};
const escapeRegExp = (str) => str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
const applyStyleToChar = (currentSegment, nextSegment, options) => {
  if (!options.wrapChars || options.wrapChars.length === 0) {
    return currentSegment;
  }
  let updatedSegment = currentSegment;
  options.wrapChars.forEach((rule) => {
    const chars = typeof rule.char === "string" ? [rule.char] : rule.char;
    const validChars = chars.filter((ch) => Array.from(ch).length === 1);
    if (validChars.length === 0) {
      console.error(`Invalid configuration in wrapChars: rule.char does not contain any valid single character.`);
      return;
    }
    let label;
    if (validChars.length === 1) {
      label = rule.label ? rule.label : validChars[0];
    } else {
      if (!rule.label) {
        console.error(
          `Invalid configuration in wrapChars: when grouping multiple characters, a label must be provided.`
        );
        return;
      }
      label = rule.label;
    }
    const validClassRegex = /^[^"'<>\s]+$/;
    if (!validClassRegex.test(label)) {
      console.error(
        `Invalid configuration in wrapChars: '${validChars.join(",")}' is used. To be used as a CSS class, the 'label' property must not contain spaces, quotes, '<' or '>'. Skipping this wrapping rule.`
      );
      return;
    }
    const className = `typesetting-char-${label}`;
    const escapedChars = validChars.map((ch) => escapeRegExp(ch)).join("");
    const regex = new RegExp(`([${escapedChars}]+)`, "gu");
    updatedSegment = updatedSegment.replace(regex, (match) => `<span class="${className}">${match}</span>`);
  });
  return updatedSegment;
};
export {
  applyKerningToSegment,
  applyStyleToChar,
  applyStyleToSegment,
  applyStyleToText
};
