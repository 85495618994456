import HTMLProcessor from "./html-processor.js";
import insertSeparatorsToText from "./insert-separators.js";
import { applyStyleToText, applyStyleToSegment, applyKerningToSegment, applyStyleToChar } from "./apply-style.js";
import win from "./win.js";
class Typesetter extends HTMLProcessor {
  /**
   * Typesetter のデフォルトの設定を返します。
   */
  static getDefaultOptions() {
    return {
      useWordBreak: true,
      wrapLatin: true,
      wrapChars: [],
      noSpaceBetweenNoBreaks: true,
      insertThinSpaces: true,
      thinSpaceWidth: "0.2em",
      kerningRules: []
    };
  }
  constructor(options = {}) {
    const isIntlSegmenterSupported = typeof Intl.Segmenter !== "undefined";
    if (!isIntlSegmenterSupported) {
      console.warn(
        "Intl.Segmenter is not supported in this environment. \nTherefore, the options `useWordBreak` and `insertThinSpaces` will be ignored. \nFor more information about Intl.Segmenter, please visit:\nhttps://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Segmenter"
      );
    }
    const transformFunctions = isIntlSegmenterSupported ? [applyStyleToText, insertSeparatorsToText, applyStyleToSegment, applyKerningToSegment, applyStyleToChar] : [applyStyleToSegment, applyKerningToSegment, applyStyleToChar];
    const validatedOptions = Typesetter.validateOptions(options);
    super(transformFunctions, validatedOptions);
  }
  /**
   * 与えられたオプションを検証し、修正されたオプションを返します。
   *
   * @param options - 検証するオプション。
   * @return 修正されたオプション。
   */
  static validateOptions(options) {
    if (options.kerningRules) {
      options.kerningRules = options.kerningRules.filter(Typesetter.isValidKerningRule);
    }
    return {
      ...Typesetter.getDefaultOptions(),
      ...options
    };
  }
  /**
   * カーニングルールが適切かどうかを検証します。
   *
   * @param kerningRule - 検証するカーニングルール。
   * @return ルールが有効な場合はtrue、そうでない場合はfalse。
   */
  static isValidKerningRule(kerningRule) {
    if (kerningRule.between[0].length !== 1 || kerningRule.between[1].length !== 1) {
      console.warn(
        `Kerning rule between '${kerningRule.between[0]}' and '${kerningRule.between[1]}' must be single characters.`
      );
      return false;
    }
    return true;
  }
  /**
   * 与えられたHTML文字列を処理し、指定されたスタイルと区切り文字を適用した結果を返します。
   *
   * @param srcHtml - 処理するHTML文字列。
   * @return 処理後のHTML文字列。
   */
  render(srcHtml) {
    if (!srcHtml) return "";
    return this.processHtmlWithFunctions(srcHtml);
  }
  /**
   * 与えられたElementまたはElementの配列に対して、スタイルと区切り文字を適用します。
   * 各要素のinnerHTMLを取得し、変換処理を適用した後、変換されたHTMLで元の内容を置き換えます。
   *
   * @param elements - スタイルを適用するElementまたはHTMLElementの配列。
   */
  renderToElements(elements) {
    if (!elements) return;
    if (!Array.isArray(elements)) {
      elements = [elements];
    }
    elements.forEach((element) => {
      const processedHtml = this.render(element.innerHTML);
      element.innerHTML = processedHtml;
    });
  }
  /**
   * 指定されたCSSセレクタに一致するすべての要素にスタイルを適用します。
   * document.querySelectorAllを使用して要素を取得し、それぞれに対してスタイル適用処理を行います。
   *
   * @param selector - スタイルを適用する要素を選択するCSSセレクタ。
   */
  renderToSelector(selector) {
    if (!selector) return;
    const elements = win.document.querySelectorAll(selector);
    this.renderToElements(Array.from(elements));
  }
}
var index_default = Typesetter;
export {
  index_default as default
};
